@tailwind base;

@import url("http://fonts.cdnfonts.com/css/product-sans");
.purple {
  background-color: #d9d7f1;
}

.yellow {
  background-color: #fffdde;
}

.green {
  background-color: #e7fbbe;
}

.pink {
  background-color: #ffcbcb;
}

@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Product Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
